import moment from "moment";
import hasProp from "ant-design-vue/lib/_util/props-util";
import http from "@/http";
import { listaStatus } from '../../util';
import EventMixin from "@/mixins/EventMixin";
import ModalTerms from "@/components/modal-terms/modalTerms";
import {isArray} from "@/resources/util";

export default {

    mixins: [EventMixin],
    props: {

        visible: { Type: Boolean },
        data: { Type: Object, },
    },
    created() {

        let someVisible;

        this.$watch(() => this.visible, is => {

            if (is && !someVisible) {

                someVisible = true;

                this.$nextTick(() => {

                    this.$onEvent(this.$refs.modal.$vnode.elm.childNodes[1].firstChild, 'animationend', () => {

                        if (!this.visible) {

                            this.resetForm();
                        }
                    })
                })
            }

            this.isVisible = is;
        });

        this.$watch(() => this.data, (value, old) => {

            if (!value) return;

            const { status, vistoria } = value;

            this.model.vistoria_id = vistoria.id;

            this.status = listaStatus[status];

        });

    },
    data() {

        return {

            loading: false,
            isVisible: hasProp(this, 'visible') ? this.visible : false,
            termosModal: false,
            aceite: false,
            model: {

                vistoria_id: undefined,
                observacao: undefined,
                status: 'CT',
                anexos: {

                    inserts: []
                }
            },
            listUpload: [],
            rules: {

                observacao: {
                    min: 3,
                    required: true,
                    message: 'Observação precisa possuir mais 3 caracteres.'
                }
            },
            status: {}
        }
    },
    methods: {
        handleVisibleChange(is) {

            this.$emit('visibleChange', is);
        },
        handleClose() {

            this.handleVisibleChange(false);
            this.$nextTick(() => {

                Object.assign(this.model, {
                    vistoria_id: undefined,
                    observacao: undefined,
                });

                this.loading = false;
            });
        },
        resetForm() {

            this.$refs.form.clearValidate();

            Object.assign(this.model, {
                vistoria_id: undefined,
                observacao: undefined,
            });

            this.aceite = false;
        },
        handleTerm(val) {

            this.aceite = val;
            this.termosModal = false;
        },
        handleFileUpload({ target: { files } }) {

            if (files.length > 10) {

                return this.$warning({
                    title: 'Ops, por favor envie até 10 arquivos!',
                    content: 'O tamanho máximo de cada arquivo deve ser inferior a 10MB.'
                });
            }

            for (let x = 0; x < files.length; x++) {

                const file = files[x];
                this.listUpload.push(file);
            }
        },
        handleRemoveFileList(index) {
            // if (!index) return;
            this.listUpload.splice(index, 1);
        },
        getIdxAnexos() {

            for (let i in this.listUpload) {

                this.model.anexos.inserts.push({ index: parseInt(i) });
            }
        },
        handleSubmit(e) {

            e.preventDefault();
            this.$refs.form.validate(valid => {

                if (valid && this.aceite) {

                    this.loading = true;
                    this.getIdxAnexos();

                    let formData = new FormData();
                    formData.append('data', JSON.stringify(this.model));

                    for (let upload of this.listUpload) {

                        formData.append('anexos', upload);
                    }

                    http.post('/painel/vistorias.status.salvar', formData).then(({ data }) => {

                        if (data.id) {

                            this.$emit('submit', Object.assign({}, this.model, data));
                            this.handleClose();
                        }
                    }).catch(err => {

                        this.$error({
                            title: err.error && isArray(err.error) ? err.message : 'Atenção, houve um erro.',
                            content: (
                                err.error && isArray(err.error) ? (
                                    <ul>
                                        {err.error.map(e => {

                                            return <li><div domPropsInnerHTML={e.message || e} /></li>
                                        })}
                                    </ul>
                                ) : <span>{err.message}</span>
                            )
                        });

                    }).finally(() => {

                        this.loading = false;
                    });
                }
            });
        },
    },

    render() {

        const formProps = {
            ref: "form",
            attrs: {
                id: 'form-status-vistoria'
            },
            props: {
                model: this.model,
                rules: this.rules
            },
            on: {
                submit: this.handleSubmit,
            }
        };

        return (
            <div>
                <a-modal
                    ref="modal"
                    width={680}
                    visible={this.isVisible}
                    onCancel={this.handleClose}
                    onOk={this.handleSubmit}
                    okText="Contestar"
                    maskClosable={false}
                    keyboard={false}
                    closable={!this.loading}
                    okButtonProps={{ props: { disabled: this.loading || !this.aceite } }}
                    cancelButtonProps={{ props: { disabled: this.loading } }}
                >
                    <div slot="title">
                        {this.status.icon ? <g-icon icon={this.status.icon} style={{ marginRight: '5px' }} /> : ''}
                        {this.status.action}
                        <span class="view-header-id" style={{ fontSize: '16px', marginLeft: '5px' }}>#{this.model.vistoria_id}</span>
                    </div>
                    <a-spin spinning={this.loading} tip="Aguarde...">
                        <a-form-model {...formProps}>
                            <a-row>
                                <a-alert
                                    message="Atenção"
                                    type="info"
                                    showIcon
                                >
                                    <template slot="description">
                                        <span>
                                            Descreva detalhadamente abaixo os motivos que o levaram a contestar o laudo gerado.
                                            O vistoriador irá analisar e responder. Fique de olho na lista.<br/>
                                            {this.data && this.data.vistoria && this.data.vistoria.prazo_contestacao ? <span><strong>Prazo para contestação:</strong> {moment(this.data.vistoria.prazo_contestacao).format('LLL')}</span> : ''}
                                        </span>
                                    </template>
                                </a-alert>
                            </a-row>
                            <a-row>
                                <a-form-model-item
                                    label="Descrição da contestação"
                                    prop="observacao"
                                >
                                    <a-textarea
                                        allowClear
                                        placeholder="Descreva o motivo da contestação."
                                        maxLength={5000}
                                        autoSize={{ minRows: 5, maxRows: 10 }}
                                        v-model={this.model.observacao}
                                    />

                                </a-form-model-item>
                            </a-row>
                            <a-row>
                                <a-form-model-item >
                                    <span style="color: rgba(0, 0, 0, 0.85);">
                                        Upload de arquivos
                                        <a-button
                                            type="primary"
                                            shape="circle"
                                            style="margin-left: 5px"
                                            onClick={() => this.$refs.anexos.click()}
                                        >
                                            <g-icon icon={['fas', 'plus']} />
                                        </a-button>
                                    </span>

                                    <input
                                        multiple
                                        type="file"
                                        ref="anexos"
                                        onChange={this.handleFileUpload}
                                        style={{ display: 'none' }}
                                        accept=".jpg, .png, .jpeg, .mp4, .wmv, .webm, .mov"
                                    />
                                    {!this.listUpload.length && <a-empty style="height: 100%" />}
                                    <div class="ant-upload-list ant-upload-list-text">
                                        {
                                            this.listUpload.map((item, index) => {
                                                return (
                                                    <span>
                                                        <div class="ant-upload-list-item ant-upload-list-item-done ant-upload-list-item-list-type-text">
                                                            <div class="ant-upload-list-item-info">
                                                                <div style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
                                                                    <g-icon icon={['fal', 'paperclip']} style={{ marginRight: '5px' }} />
                                                                    <span style="color: #1890ff;"> {item.name} </span>
                                                                    <span class="ant-upload-list-item-card-actions" style="color: #f5222d" onClick={() => this.handleRemoveFileList(index)}>
                                                                        <g-icon icon={['fas', 'trash']} />
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </span>
                                                );
                                            })
                                        }
                                    </div>

                                </a-form-model-item>
                            </a-row>
                            <a-row>
                                <a-button type="link" onClick={() => this.termosModal = true}>
                                    <g-icon icon={['far', this.aceite ? 'check-square' : 'square']} />
                                    Eu li e concordo com os termos de serviço
                                </a-button>
                            </a-row>
                        </a-form-model>
                    </a-spin>
                </a-modal>

                <ModalTerms
                    visible={this.termosModal}
                    onAcceptTerm={(val) => this.handleTerm(val)}
                />
            </div>
        )
    }
}
