import './index.less';
import http from "@/http";
import ModalStatus from '@/modules/vistoria/common/ModalActions';
import ModalContestar from '@/modules/vistoria/common/ModalContest';
import ModalDetalhes from '@/modules/vistoria/common/ModalDetails';
import DrawerTimeLine from '../drawer';
import moment from "moment";
import {listaStatus, listaTipoVistoria} from '../../util';

export default {

    data() {

        return {

            statusData: null,
            visibleInfo: false,
            visibleModal: false,
            visibleModalCT: false,
            detailContest: {

                visible: false,
                status_id: undefined,
            },
            drawer: {

                visible: false,
                data: null,
            },
            list: [],
            loading: null,
            pagination: {

                showSizeChanger: true,
                current: 1,
                showTotal: (total, range) => `${range[0]}-${range[1]} de ${total} itens`,
                onChange: (page) => this.pagination.current = page
            },
        }
    },
    beforeMount() {

        if (!this.session.user.ator) {

            this.$router.push({name: "main"});
        }
    },
    created() {

        this.loading = true;
        this.load();
    },
    computed: {

        session() {

            return this.$store.getters['session/user'];
        }
    },
    methods: {

        load() {

            http.get('painel/vistorias.listar').then(({data}) => {

                this.list = data.items.reverse();

            }).finally(() => this.loading = false).catch(err => {

                this.$error({
                    title: 'Desculpe, houve um erro!',
                    content: 'Tente novamente mais tarde.',
                });
            });
        },
        handleModal(vistoria, status) {

            this.statusData = {

                vistoria,
                status
            };

            if (status === 'CT') {

                this.visibleModalCT = !this.visibleModalCT;
            } else {

                this.visibleModal = !this.visibleModal;
            }
        },
        handleSubmitNewStatus(data) {

            if (!data) return;

            this.$message.success('Dados salvos.');

            this.load();
        },
        openLaudo(vistoria, {previa = false} = {}) {

            this.loading = true;

            let laudo = {vistoria_id: vistoria.id};
            if (previa) laudo.previa = 1;

            if (previa && !~['CL', 'PA', 'AS', 'GL', 'L', 'CT', 'F'].indexOf(vistoria.status[0].status)) {

                http.get('/painel/vistorias.laudos.obter', {params: laudo, responseType: 'blob'}).then(data => {

                    let url = window.URL.createObjectURL(new Blob([data], {type: 'application/pdf'}));
                    this.windowOpen(url);

                }).catch(err => {

                    this.$error({

                        title: 'Desculpe, houve um erro ao recuperar o laudo.',
                    });

                }).finally(() => this.loading = false);

            } else {

                http.get('/painel/vistorias.laudos.obter', {params: laudo}).then(data => {

                    this.windowOpen(data.data.arquivo);

                }).catch(err => {

                    this.$error({

                        title: 'Desculpe, houve um erro ao recuperar o laudo.',
                    });

                }).finally(() => this.loading = false);
            }
        },
        windowOpen(url) {

            if (!window.open(url, '_blank')) {

                this.$warning({
                    title: 'Ops, o navegador está bloqueando popups.',
                    content: h => <div><p>Clique no link para abrir: <a href={url} target="_blank">{url}</a></p></div>
                })
            }
        },
        openGallery(record) {

            setTimeout(() => this.$router.push({name: 'galeria', params: {id: record.id}, query: {back: 'minhas-vistorias'}}), 1);
        },
        downloadFotos(id) {

            if (!id) return;

            this.loading = true;

            http.get('/painel/vistorias.downloads', {params: {id: id}, responseType: 'blob'}).then(data => {

                const url = window.URL.createObjectURL(new Blob([data], {type: 'application/zip'}));
                this.windowOpen(url);

            }).finally(() => this.loading = false).catch(err => {

                const reader = new FileReader;
                reader.onload = () => {

                    const error = JSON.parse(reader.result);
                    this.$error({
                        title: 'Desculpe, houve um erro ao recuperar o laudo.',
                        content: error.message
                    });
                }
                reader.readAsText(err);
            });
        },
        todosAssinaram(record) {

            const assinam = record.vistoria_ator ? record.vistoria_ator.filter(item => item.assina) : [];
            return record.vistoria_ator && (record.vistoria_ator.filter(item => !!item.assinou_em)).length === assinam.length;
        },
        possoContestar(record) {

            const find = record.status.find(item => item.status === 'L');
            let valid = false;

            if (find && record.cliente.config && record.cliente.config.prazo_contestacao) {

                const prazo = moment(find.data).add(record.cliente.config.prazo_contestacao, 'days');
                record.prazo_contestacao = prazo;
                valid = moment().isBefore(prazo);
            }

            return record.status[0].status !== "F" && find && valid;
        },
        handleDrawer(vistoria) {

            if (!vistoria) return;

            const {status} = vistoria;

            Object.assign(this.drawer, {

                visible: true,
                data: {

                    vistoria_id: vistoria.id,
                    status
                }
            });
        },
        handleDetailsContest(vistoria, ator) {

            if (!vistoria) return;

            const statusCT = vistoria.status.find(item => item.status === 'CT');

            Object.assign(this.detailContest, {

                visible: true,
                data: {

                    status_id: statusCT.id,
                    ator_id: ator.id
                }
            });
        }
    },
    render() {

        return (
            <div class="view view-body-scrollable">
                <div class="view-header">
                    <div class="view-header-body">
                        <div class="view-header-start">

                            <g-icon icon={['fas', 'clipboard-list']}/>
                            <h1>Minhas Vistorias</h1>
                            <span
                                style="margin-left: 10px; cursor: pointer;"
                                onClick={() => this.visibleInfo = !this.visibleInfo}
                            >
                                <g-icon icon={['far', 'question-circle']} style={{fontSize: '20px'}}/>
                            </span>
                        </div>
                    </div>
                </div>

                <a-modal
                    footer={null}
                    title='Informações'
                    visible={this.visibleInfo}
                    bodyStyle={{maxHeight: '70vh'}}
                    onCancel={() => this.visibleInfo = !this.visibleInfo}
                >
                    <p>Acompanhe por aqui o andamento da(s) vistoria(s) do seu imóvel.</p>
                    <p><b>Status:</b> é o andamento atual da vistoria;</p>
                    <p><b>Opções:</b> nas vistorias concluídas, prontas para assinar e finalizadas,
                        clique no botão de menu ao fim da linha (
                        <g-icon icon={['fas', 'ellipsis-v']}/>
                        ),
                        ou mantenha pressionado no celular, para ter acesso ao menu de opções;
                    </p>
                    <p><b>Prévia do laudo:</b> antes de assinar, veja todas as informações do laudo
                        e revise cada item;</p>
                    <p><b>Revisão das partes:</b> quando o laudo precisar ser assinado por mais de
                        uma pessoa, vá até o ícone <g-icon icon={['far', 'eye']}/> para ver quem
                        já assinou e quem ainda está pendente. O laudo final será gerado quando a
                        última pessoa assinar.
                    </p>
                    <p><b>Assinar:</b> clique para assinar digitalmente o laudo, ele será gerado
                        assim que todos os envolvidos assinarem;</p>
                    <p><b>Contestar:</b> se alguma informação estiver incorreta e/ou você não
                        concordar com o laudo, utilize esta opção descrevendo detalhadamente a sua
                        contestação no campo de observação e aguarde um posicionamento do
                        vistoriador;</p>
                    <p><b>Visualizar laudo:</b> após concluído o processo, é gerado o laudo final
                        assinado e definitivo, e você pode acessá-lo nesta opção.</p>
                    <p><b>Atendimento:</b> na visualização da linha do tempo procure pelo link
                        <b>
                            <g-icon icon={['fas', 'phone-rotary']}/>
                            Atendimento </b>
                        para enviar uma mensagem à empresa responsável pela vistoria. A mesma também
                        pode iniciar um atendimento com você, como quando há uma contestação, por
                        exemplo. Procure pelo link de atendimento com o ícone
                        <g-icon icon={['fas', 'phone-volume']} fixed-width/> para identificar um
                        atendimento iniciado.
                    </p>

                </a-modal>

                <ModalStatus
                    visible={this.visibleModal}
                    data={this.statusData}
                    onVisibleChange={(value) => this.visibleModal = value}
                    onSubmit={this.handleSubmitNewStatus}
                />

                <ModalContestar
                    visible={this.visibleModalCT}
                    data={this.statusData}
                    onVisibleChange={(value) => this.visibleModalCT = value}
                    onSubmit={this.handleSubmitNewStatus}
                />

                <ModalDetalhes
                    visible={this.detailContest.visible}
                    data={this.detailContest.data}
                    onVisibleChange={(visible) => this.detailContest.visible = visible}
                />

                <DrawerTimeLine
                    visible={this.drawer.visible}
                    data={this.drawer.data}
                    onVisibleChange={(visible) => this.drawer.visible = visible}
                />

                <div id='vistoria-list'>
                    {this.list.map(record => {

                        const tipo = listaTipoVistoria[record.tipo];
                        const filtered = record.status.find(item => item.status !== 'AS');
                        const status = filtered ? listaStatus[filtered.status] : null;
                        const ator = record.vistoria_ator.find(item => this.session.user && item.ator.id === this.session.user.ator.id);
                        const isAssinaturaDigital = record.config?.assinar === 1;

                        return (

                            <a-card
                                class='vistoria-list-card'
                            >
                                <div slot='title' style='display: inline-flex; align-items: center;'>
                                    <span class='title'>Vistoria #{record.id}</span>
                                    <a-tag style="margin-left: 14px;" color={tipo.color}>Vistoria de {tipo.label}</a-tag>
                                </div>
                                <template slot='default'>
                                    <div class='item'>
                                    <span class='item-title'>
                                        Endereço do imóvel:
                                    </span>
                                        <span>
                                        <g-icon icon={['far', 'map-pin']} fixed-width/>
                                            {record.imovel.logradouro}, nº {record.imovel.numero} - {record.imovel.bairro}, {record.imovel.cidade.nome}-{record.imovel.cidade.estado.uf}
                                    </span>
                                    </div>
                                    <div class='item'>
                                    <span class='item-title'>
                                        Imobiliária:
                                    </span>
                                        <span>
                                        <g-icon icon={['far', 'university']} fixed-width/>
                                            {record.cliente.pessoa === "J" ? (record.cliente.nome_fantasia || record.cliente.razao_social) : record.cliente.nome}
                                    </span>
                                    </div>
                                    <div class='item'>
                                    <span class='item-title'>
                                        Vistoriador:
                                    </span>
                                        <span>
                                        <g-icon icon={['far', 'id-badge']} fixed-width/>
                                            {record.vistoriador?.nome ?? 'Não informado'} ({record.organizacao.nome_fantasia})
                                    </span>
                                    </div>
                                    <div class='item'>
                                    <span class='item-title'>
                                        Última modificação:
                                    </span>
                                        <a-tooltip
                                            title={!record.status.length ? 'Não informado.' : `${moment(record.status[0].data || record.status[0].c_em).format('DD/MM/YYYY HH:mm')}`}
                                            placement="bottom"
                                        >
                                            <g-icon icon={['far', 'clock']} fixed-width/>
                                            <span style='margin-left: 3px;'>
                                            {!record.status.length ? 'Não informado.' : `${moment(record.status[0].data || record.status[0].c_em).fromNow()}`}
                                        </span>
                                        </a-tooltip>
                                    </div>
                                    <div class='item' v-show={!!status}>
                                    <span class='item-title'>
                                        Status:
                                    </span>
                                        <span>
                                        <a-badge status={status.status} text={status.label}/>
                                    </span>
                                    </div>
                                    <div class='item'
                                         v-show={isAssinaturaDigital && ['AS', 'CT', 'GL', 'L', 'F', 'PA'].includes(record.status[0].status) && record.vistoria_ator}>
                                        <span class='item-title'>Trilha de assinaturas:</span>
                                        {(() => {

                                            const atorStatus = item => {

                                                return {

                                                    AS: {
                                                        color: 'green',
                                                        icon: ['fas', 'file-signature'],
                                                        text: (<span
                                                            style="color: green; font-size: 10px;">Assinou em <i>{moment(item.assinou_em).format('DD/MM/YYYY HH:mm')}</i></span>)
                                                    },
                                                    CT: {
                                                        color: 'red',
                                                        icon: ['fas', 'thumbs-down'],
                                                        text: (
                                                            <div style="display: inline-flex; flex-direction: column;">
                                                                <span
                                                                    style="color: red; font-size: 10px;">Contestou em <i>{moment(item.contestacao.contestado_em).format('DD/MM/YYYY HH:mm')}</i></span>
                                                                <span
                                                                    style="font-size: 10px; color: rgba(0,0,0,.85); display: inline-block; cursor: pointer;"
                                                                    onmouseover={(e) => e.target.style.color = '#1890ff'}
                                                                    onmouseout={(e) => e.target.style.color = 'rgba(0,0,0,.85'}
                                                                    onClick={() => this.handleDetailsContest(record, item)}
                                                                >
                                                                    <g-icon icon={['fas', 'search']} fixedWidth/> Ver detalhes
                                                                </span>
                                                            </div>
                                                        )
                                                    }
                                                }
                                            };

                                            return (
                                                <a-timeline style="margin-top: 16px;">
                                                    {

                                                        record.vistoria_ator.filter(item => item.assina).map(item => {

                                                            item.status = (item.contestacao && item.contestacao.id) ? 'CT' : (item.assinou_em ? 'AS' : null);

                                                            return (
                                                                <a-timeline-item key={item.id}
                                                                                 color={item.status ? atorStatus(item)[item.status]['color'] : 'blue'}>
                                                                    <g-icon-layers slot="dot" class="fa-lg">
                                                                        <g-icon icon={['far', 'circle']}/>
                                                                        {
                                                                            item.status ?
                                                                                <g-icon
                                                                                    icon={atorStatus(item)[item.status]['icon']}
                                                                                    transform="shrink-9"/> :
                                                                                <g-icon
                                                                                    icon={['fas', 'question']}
                                                                                    transform="shrink-9"/>
                                                                        }
                                                                    </g-icon-layers>
                                                                    <b>{item.ator.nome}</b><br/>
                                                                    {item.status ? atorStatus(item)[item.status]['text'] :
                                                                        <i style="font-size: 10px;">Pendente</i>}
                                                                </a-timeline-item>
                                                            )
                                                        })
                                                    }
                                                </a-timeline>
                                            )
                                        })()}
                                    </div>
                                    <div class='item' style={`display: inline-flex; flex-direction: row; margin-top: ${isAssinaturaDigital ? '0' : '10'}px;`}>
                                        <a-button
                                            v-show={record.status.find(item => item.status === 'PA') && !['GL', 'L', 'F', 'CT'].includes(record.status[0].status) && ator && !ator.assinou_em}
                                            type="link"
                                            style="color: #87d068;"
                                            onClick={() => this.handleModal(record, 'AS')}
                                        >
                                            <g-icon icon={['fas', 'file-signature']}/>
                                            <span style='margin-left: 6px;'>Assinar laudo</span>
                                        </a-button>
                                        <a-button
                                            v-show={record.status.find(i => i.status === 'L')}
                                            type="link"
                                            style="color: #7265e6;"
                                            onClick={() => this.openLaudo(record)}
                                        >
                                            <g-icon icon={['fas', 'file-pdf']}/>
                                            <span style='margin-left: 6px;'>Visualizar laudo</span>
                                        </a-button>
                                        <a-button
                                            v-show={~['CL', 'PA', 'AS'].indexOf(record.status[0].status) && !this.todosAssinaram(record)}
                                            type="link"
                                            style="color: #7265e6;"
                                            onClick={() => this.openLaudo(record, {previa: true})}
                                        >
                                            <g-icon icon={['fas', 'file-pdf']}/>
                                            <span style='margin-left: 6px;'>Prévia do laudo</span>
                                        </a-button>
                                        <a-button
                                            v-show={this.possoContestar(record) && ator && !(ator.contestacao && ator.contestacao.id)}
                                            type="link"
                                            style="color: #c91212;"
                                            onClick={() => this.handleModal(record, 'CT')}
                                        >
                                            <g-icon icon={['fas', 'file-signature']}/>
                                            <span style='margin-left: 6px;'>Contestar vistoria</span>
                                        </a-button>
                                    </div>
                                </template>
                                <template slot="actions" class="ant-card-actions">
                                    <a-button
                                        type="link"
                                        onClick={() => this.handleDrawer(record)}
                                    >
                                        <g-icon icon={['fas', 'eye']}/>
                                        <span style='margin-left: 6px;'>Linha do Tempo</span>
                                    </a-button>
                                    <a-button
                                        type="link"
                                        onClick={() => this.openGallery(record)}
                                    >
                                        <g-icon icon={['fas', 'photo-video']} fixedWidth/>
                                        <span style='margin-left: 6px;'>Ver galeria</span>
                                    </a-button>
                                </template>
                            </a-card>
                        )
                    })}
                </div>
            </div>
        )
    }
}
